import $ from "jquery";
import { triggerAnalyticsEvent } from '../shared/analytics'

const showMiners = ({ teamHiveContainer }) => {
  const teamHiveHeight = document.getElementsByClassName(
    "team-hive__container"
  )[0].scrollHeight;

  teamHiveContainer.addClass("show");
  teamHiveContainer.css("max-height", teamHiveHeight + 200);
  teamHiveContainer.css("height", teamHiveHeight + 40);
};

const hideMiners = ({
  seeAll,
  seeLess,
  teamHiveContainer,
  gradientControl,
}) => {
  gradientControl.removeClass("hidden");
  seeAll.removeClass("hidden");
  teamHiveContainer.removeClass("show");
  seeLess.addClass("hidden");
  setTimeout(() => {
    teamHiveContainer.css("max-height", "");
  }, 0);
};

$(document).ready(() => {
  const teamHiveContainer = $('[data-id="team-hive-container"]');
  const seeAll = $('[data-id="see-all"]');
  const seeLess = $('[data-id="see-less"]');
  const gradientControl = $('[data-id="gradient-white"]');

  teamHiveContainer.on(
    "transitionend webkitTransitionEnd oTransitionEnd",
    function () {
      if (teamHiveContainer.hasClass("show")) {
        gradientControl.addClass("hidden");
        seeAll.addClass("hidden");
        seeLess.removeClass("hidden");
      }
    }
  );

  seeAll.on("click", function () {
    showMiners({ teamHiveContainer });
    triggerAnalyticsEvent("show_all_team");
  });

  seeLess.on("click", function () {
    hideMiners({ seeAll, seeLess, teamHiveContainer, gradientControl });
  });

  handleHiveYouMembers();
  window.addEventListener("resize", handleHiveYouMembers);
});

const getHiveContainer = () => {
  return document.querySelector(".team-hive__container");
};

const destroyYouCombs = () => {
  const tempCombs = getHiveContainer().querySelectorAll(".you-comb");
  tempCombs.forEach((comb) => {
    comb.remove();
  });
};

const addYouCombToHive = (imgUrl) => {
  const comb = document.createElement("a");
  comb.href = "/careers";
  comb.classList.add("developer", "you-comb");
  comb.tabIndex = -1

  const img = document.createElement("img");
  img.src = imgUrl;
  img.alt = 'Cartoon Person'
  img.classList.add("developer_image");

  const name = document.createElement("h3");
  name.classList.add("developer__description");
  name.innerText = "You";

  comb.append(img);
  comb.append(name);

  getHiveContainer().append(comb);
};

const renderYouCombs = () => {
  const lastComb = getHiveContainer().lastElementChild;
  const hiveContainerWidth = getHiveContainer().offsetWidth;
  const { right } = lastComb.getBoundingClientRect();
  const missingCombsCount = Math.floor((hiveContainerWidth - right) / 160);
  const combImageA = "/comb1.png";
  const combImageB = "/comb2.png";

  for (let comb = 0; comb < missingCombsCount; comb++) {
    const image = comb % 2 === 0 ? combImageA : combImageB;
    addYouCombToHive(image);
  }
};

const handleHiveYouMembers = () => {
  destroyYouCombs();

  renderYouCombs();
};
